body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin: 0 auto;
  padding: 0 4rem;
  max-width: 100rem;
}

.edit-event {
  position: fixed;
  top: 100px;
  left: 100px;
  width: 40rem;
  background: white;
  border: 2px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.5);
  filter: blur(0);
  transform: scale(1);
  opacity: 1;
  visibility: visible;
  padding: 1rem;
}
.edit-event.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

.edit-event .edit-fields{
  display:flex; 
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.edit-event .edit-control {
  display: flex;
  justify-content: space-between;
  margin: 0.1rem 0;
}

.edit-event .edit-control input {
  margin-left: 1rem;
}

.head {
  display: flex;
  justify-content: flex-end;
}

.add-event-container {
  margin: 1rem 0 0 0;
}

.drop-down-btn {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-weight: bold;
  font-size: 16pt;
  margin-bottom: 1rem;
}

.drop-down-btn .fa-angle {
  position: relative;
  top: 0.2rem;
  font-size: 16pt;
  margin-left: 0.5rem;
}

.add-form {
  display: flex;
  justify-content: left;
  flex-direction: row;
  width: 100%;
}

.add-form .column {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 20rem;
  margin-right: 2rem;
}

.add-form .column .add-control {
  display: flex;
  justify-content: space-between;
  margin: 0.1rem 0;
}

.add-form .row {
  display: flex;
  flex-direction: row;
}

.add-form .row .add-control {
  margin-right: 2rem;
}

.add-form .row input {
  margin-left: 1rem;
}

.add-form .submit-btn {
  margin: 1rem 0;
  width: 6rem;
}

.filters {
  margin: 1rem 0 2rem 0;
}

.filter-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filter-form input {
  margin-left: 1rem;
}

.page-button-container {
  margin: 0 auto;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  width: 40%;
  height: 2rem;
}

.page-button-container button {
  width: 4rem;
}

.page-button-container p {
  margin: auto 0;
  font-size: 1.2rem;
  font-weight: bold;
}

table {
  border-collapse: collapse;
  width: 100%;
}

tbody tr:nth-child(odd) {
  background: #eee;
}

table .delete-column {
  display:flex;
  height: 100%;
  padding: auto;
  justify-content: space-around;
}

table .delete-column p {
  height: 100%;
  margin: 0;
  padding: 0;
}

table .delete-column button {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  font-size: 14pt;
}

th, td {
  padding: 0.25rem;
  text-align: left;
  border: 1px solid #ccc;
}

td .delete-column {
  padding: 0;
}
